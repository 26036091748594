@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");
@import "../../constants";

.centered_block {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 50%;
    margin-top: 150px;
    transform: translateX(-50%);
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    background: $whiteBg;

    .form {
        display: flex;
        flex-direction: column;
        border: 2px solid $borderColor;
        border-radius: 16px;
        width: 400px;
        min-height: 300px;
        margin: 0 10px;

        .centered {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-weight: 600;
                font-size: 20px;
                margin-top: 10px;
            }

            button {
                margin: 15px 0 15px 0;
                font-size: 18px;
                cursor: pointer;
                padding: 4px 6px;
                border-radius: 8px;
                border: 2px solid $borderColor;
                background: $whiteBg;
                color: black;
            }
        }

        .form_group {
            display: flex;
            flex-direction: column;
            margin: 2px 5px 5px 5px;

            label {
                margin-bottom: 5px;
            }

            input {
                border: 2px solid #5f5f5f;
                border-radius: 8px;
                padding: 6px 8px;
                font-size: 16px;
            }

            input:focus {
                border-color: black;
                box-shadow: 0 0 0 0.02rem black;
            }
        }

        .error_block {
            margin: 2px 5px 5px 5px;

            p {
                margin: 0;
                color: $errorColor;
                font-size: 16px;
            }
        }
    }

    @media (max-width: 500px) {
        .form {
            width: 350px;
        }
    }

    @media (max-width: 400px) {
        .form {
            width: 300px;
        }
    }

    @media (max-width: 320px) {
        .form {
            width: 270px;
        }
    }

    @media (max-width: 290px) {
        .form {
            width: 230px;
        }
    }
}

@media (max-height: 600px) {
    .centered_block {
        position: absolute;
        margin: 0;

        .form {
            margin: 20px 10px;
        }
    }
}

