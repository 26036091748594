@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");
@import "../../../constants";


.text_editor {
  font-family: 'Montserrat', sans-serif;
  background: $corporealBg;

  .editor_block {
    height: 85vh;
    display: block;
    flex-direction: row;
    justify-content: center;
    padding: 0 25px 1rem 25px;
    margin-top: 115px;

    .header_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0 25px 0 25px;
      margin-top: 10px;

      .header_block {
        max-width: 750px;
        width: 100%;

        p {
          margin: 0;
          font-family: 'Montserrat', sans-serif;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }

    .semantic_block:nth-child(2n) {
      margin-bottom: 60px;
    }

    .toolbar_class {
      width: calc(100% - 2 * 25px);
      left: 25px;
      right: 25px;
      border: 0 solid #ccc;
      background: #eaeafa;
      border-radius: 16px;
      position: -webkit-sticky;
      position: fixed;
      z-index: 50;
      opacity: 0!important;
      visibility: hidden!important;
      transition: top 0.3s ease-in-out;

      .rdw-option-wrapper {
        background: inherit;
        border: 0 solid #fff;
        border-radius: 4px;
      }

      .rdw-option-wrapper:hover {
        background: #d5d5d5;
        border: 0;
        box-shadow: none;
      }

      .rdw-option-active {
        background: #c9c9ff!important;
        box-shadow: none;
      }
    }

    .sticky_class {
      top: 60px;
    }

    .no_sticky_class {
      top: 95px;
    }

    @media (max-width: 693px) {
      .no_sticky_class {
        top: 120px;
      }

      .sticky_class {
        top: 85px;
      }

      .toolbar_class {
        .rdw-option-wrapper {
          height: 16px;
          font-size: 12px;
        }
      }
    }

    @media (max-width: 485px) {
      .no_sticky_class {
        top: 120px;
      }

      .sticky_class {
        top: 85px;
      }

      .toolbar_class {
        .rdw-option-wrapper {
          height: 14px;
          font-size: 11px;

          img {
            width: 10px;
            height: 10px;
          }
        }
      }
    }

    @media (min-width: 467px) and (max-width: 480px) {
      .no_sticky_class {
        top: 100px;
      }

      .sticky_class {
        top: 65px;
      }

      .toolbar_class {
        .rdw-option-wrapper {
          height: 13px;
          font-size: 10px;

          img {
            width: 9px;
            height: 9px;
          }
        }
      }
    }

    @media (min-width: 432px) and (max-width: 466px) {
      .no_sticky_class {
        top: 100px;
      }

      .sticky_class {
        top: 65px;
      }

      .toolbar_class {
        .rdw-option-wrapper {
          height: 13px;
          font-size: 10px;
          margin: 0 2.5px;

          img {
            width: 9px;
            height: 9px;
          }
        }
      }
    }

    @media (min-width: 400px) and (max-width: 432px) {
      .no_sticky_class {
        top: 120px;
      }

      .sticky_class {
        top: 85px;
      }

      .toolbar_class {
        .rdw-option-wrapper {
          height: 13px;
          font-size: 10px;
          margin: 0 1px;

          img {
            width: 9px;
            height: 9px;
          }
        }
      }
    }

    @media (max-width: 399px) {
      .no_sticky_class {
        top: 120px;
      }

      .sticky_class {
        top: 85px;
      }

      .toolbar_class {
        .rdw-option-wrapper {
          height: 13px;
          font-size: 10px;
          margin: 2px 1px;

          img {
            width: 9px;
            height: 9px;
          }
        }
      }
    }

    @media (max-width: 314px) {
      .no_sticky_class {
        top: 120px;
      }

      .sticky_class {
        top: 85px;
      }

      .toolbar_class {
        display: flex;
        flex-wrap: nowrap;

        .rdw-option-wrapper {
          height: 13px;
          font-size: 10px;
          margin: 2px 1px;

          img {
            width: 9px;
            height: 9px;
          }
        }
      }
    }

    .toolbar_active {
      opacity: 1!important;
      visibility: visible!important;
    }

    @media (max-width: 1024px) {
      .toolbar_class {
        position: static;
        display: none;
      }

      .toolbar_active {
        display: flex;
        width: 100%;
        opacity: 1!important;
        visibility: visible!important;
      }
    }

    .wrapper_class {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: inherit;
      padding: 0 25px 1rem 25px;
    }

    .header_editor_class {
      max-width: 750px;
      width: 100%;
      min-height: 135px;
      margin-top: 10px;
      border: 1px solid #afafaf;
      padding: 15px 25px;
      cursor: text;
      background: $whiteBg;
      overflow-y: hidden
    }

    .editor_class {
      max-width: 750px;
      width: 100%;
      min-height: 700px;
      margin-top: 5px;
      border: 1px solid #afafaf;
      padding: 15px 25px;
      cursor: text;
      background: $whiteBg;
      overflow-y: hidden
    }
  }

  @media (max-width: 399px) {
    .editor_block {
      margin-top: 140px;
    }
  }
}


.menu_tool_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 5px 25px 0 25px;
  margin: 0;
  position: -webkit-sticky;
  position: fixed;
  top: 35px;
  z-index: 100;
  background: $corporealBg;
  transition: top 0.3s ease-in-out;

  .header_icon {
    color: #74C0FC;
    width: 30px;
    height: 30px;
    padding: 7px;
  }

  .menu_tool_box_header {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      input {
        min-width: 100px;
        margin: 0 7px;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        padding: 3px 6px;
        border: 1px solid transparent;
        border-radius: 6px;
        width: auto;
        max-width: 100%;
        box-sizing: content-box;
      }

      input:hover {
        border: 1px solid $borderColor;
      }

      input:focus {
        border: 1px solid $borderColor;
      }

      span {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
      }

      .auto_save_icon {
        cursor: pointer;
      }

      .menu_button {
        margin: 0 7px;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        cursor: pointer;
        border: 0 solid #ccc;
        border-radius: 4px;
        padding: 3px 6px;
        text-align: center;
        transition: all 0.3s ease;
      }

      .menu_button:hover {
        background: #d5d5d5;
      }

      .menu_symbols_amount {
        margin: 0;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        padding: 3px 6px 3px 13px;
        border-left: 1px solid $borderColor;
      }

      @media (min-width: 514px) and (max-width: 693px) {
        .menu_symbols_amount {
            border-left: 1px solid $corporealBg;
        }
      }

      @media (max-width: 480px) {
        span {
          font-size: 11px;
          margin-bottom: 3px;
        }

        .menu_button {
          font-size: 11px;
          padding: 0 3px;
        }

        input {
          font-size: 11px;
          padding: 0 3px;
          margin-bottom: 3px;
        }
      }

      @media (min-width: 467px) and (max-width: 480px) {
        .menu_symbols_amount {
          border-left: 0 solid $corporealBg;
          font-size: 11px;
          padding: 0 3px;
          margin: 3px 0 0 7px;
        }
      }

      @media (min-width: 433px) and (max-width: 466px) {
        .menu_symbols_amount {
          font-size: 11px;
          padding: 0 0 0 7px;
          margin-top: 3px;
        }

        .send_on_validation {
          margin-top: 3px;
        }
      }

      @media (max-width: 432px) {
        .menu_symbols_amount {
          border-left: 0 solid $corporealBg;
          font-size: 11px;
          padding: 0 3px;
          margin: 3px 0 0 7px;
        }

        .send_on_validation {
          margin-top: 3px;
        }
      }
    }
  }

  .information_msg_block {
    position: fixed;
    top: 150px;
    right: 15px;
    width: 300px;
    min-height: 50px;
    max-height: 200px;
    border: 1px solid $borderColor;
    border-radius: 8px;
    background: $corporealBg;
    display: flex;
    flex-direction: column;
    visibility: hidden;

    .information_msg_header_block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      border-bottom: 1px solid $borderColor;

      p {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        margin: 0;
        color: green;
      }

      .information_msg_close {
        margin-right: 5px;
        cursor: pointer;
      }
    }

    .information_msg_answer {
      padding: 5px;
      word-wrap: break-word;
    }
  }

  .success_msg_block {
    position: fixed;
    top: 280px;
    right: 15px;
    width: 300px;
    min-height: 50px;
    max-height: 200px;
    border: 1px solid $borderColor;
    border-radius: 8px;
    background: $corporealBg;
    display: flex;
    flex-direction: column;
    visibility: hidden;

    .success_msg_header_block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      border-bottom: 1px solid $borderColor;

      p {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        margin: 0;
        color: green;
      }

      .success_msg_close {
        margin-right: 5px;
        cursor: pointer;
      }
    }

    .success_msg_answer {
      padding: 5px;
      word-wrap: break-word;
    }
  }

  .error_msg_block {
    position: fixed;
    top: 410px;
    right: 15px;
    min-width: 300px;
    max-width: 400px;
    min-height: 50px;
    border: 1px solid $borderColor;
    border-radius: 8px;
    background: $corporealBg;
    display: flex;
    flex-direction: column;
    visibility: hidden;

    .error_msg_header_block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      border-bottom: 1px solid $borderColor;

      p {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        margin: 0;
        color: $errorColor;
      }

      .error_msg_close {
        margin-right: 5px;
        cursor: pointer;
      }
    }

    .error_msg_answer {
      padding: 5px;
      word-wrap: break-word;

      li {
        margin-left: 20px;
      }
    }
  }

  @media (min-width: 500px) and (max-width: 1024px) {
    .error_msg_block {
      font-size: 14px;
    }

    .success_msg_block {
      font-size: 14px;
    }

    .information_msg_block {
      font-size: 14px;
    }
  }

  @media (max-width: 500px) {
    .error_msg_block {
      font-size: 12px;
    }

    .success_msg_block {
      font-size: 12px;
    }

    .information_msg_block {
      font-size: 12px;
    }
  }

  @media (max-height: 600px) {
    .error_msg_block {
      top: 240px;

      .error_msg_answer {
        max-height: 150px;
        overflow-y: auto;
      }
    }

    .success_msg_block {
      top: 140px;
    }

    .information_msg_block {
      top: 40px;
    }
  }
}


.meta_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 25px 10px 25px;
  margin: 0;
  background: $corporealBg;

  .meta_header_block {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 850px;
    justify-content: space-between;
    border: 2px solid $borderColor;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 10px;

    p {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 18px;
      margin: 0;
    }

    .toggle_meta {
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .meta_information_block {
    display: flex;
    flex-direction: column;
    max-width: 850px;
    width: 100%;
    border-left: 2px solid $borderColor;
    border-right: 2px solid $borderColor;
    border-bottom: 2px solid $borderColor;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 15px 10px;

    .form_group {
      margin-bottom: 10px;

      .form_label {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 16px;
      }

      .form_input {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 16px;
        border: 1px solid $borderColor;
      }

      .form_input:focus {
          border-color: $borderColor;
          box-shadow: 0 0 0 0.02rem $borderColor;
      }

      .form_textarea {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 16px;
        resize: vertical;
        min-height: 400px;
        max-height: 600px;
        border: 1px solid $borderColor;
      }

      .form_textarea:focus {
          border-color: $borderColor;
          box-shadow: 0 0 0 0.02rem $borderColor;
      }

      .form_image_input {
        border: 1px solid $borderColor;
        border-radius: 8px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 5px;
        padding: 5px;

        .image_selection {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        p {
          font-family: 'Montserrat', sans-serif;
          font-weight: 600;
          font-size: 16px;
          margin: 0;
        }
      }
    }

    .form_group_checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;

      .form_label_checkbox {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 16px;
        margin: 0 10px 0 0;
      }

      .form-check-input {
        border: 1px solid $borderColor;
      }

      .form-check-input:focus {
        border-color: $borderColor;
        box-shadow: 0 0 0 0.02rem $borderColor;
      }
    }
  }
}

.publication_block {
  width: calc(100% - 2 * 25px);
  position: fixed;
  z-index: 50;
  transition: top 0.3s ease-in-out;

  button {
    padding: 5px 8px;
    border: 2px solid $borderColor;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    background: $corporealBg;
  }
}

.no_sticky_publ_class {
  top: 100px;
}

.sticky_publ_class {
  top: 65px;
}

@media (max-width: 693px) {
  .no_sticky_publ_class {
    top: 120px;
  }

  .sticky_publ_class {
    top: 90px;
  }
}

@media (max-width: 486px) {
  .no_sticky_publ_class {
    top: 110px;
  }

  .sticky_publ_class {
    top: 75px;
  }

  .publication_block {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      font-size: 10px;
    }
  }
}

@media (min-width: 481px) and (max-width: 487px) {
  .no_sticky_publ_class {
    top: 125px;
  }

  .sticky_publ_class {
    top: 90px;
  }
}

@media (min-width: 396px) and (max-width: 432px) {
  .no_sticky_publ_class {
    top: 125px;
  }

  .sticky_publ_class {
    top: 85px;
  }
}

@media (max-width: 395px) {
  .no_sticky_publ_class {
    top: 130px;
  }

  .sticky_publ_class {
    top: 90px;
  }
}

.RED {
    color: red;
}

.project_view_block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .project_view_menu {
    width: 1000px;
    height: calc(100% - 2 * 100px);
    margin: 0 25px;
    border: 2px solid $borderColor;
    border-radius: 32px;
    display: flex;
    background: $whiteBg;
    flex-direction: column;

    .project_view_header {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 10px 20px;
      border-bottom: 2px solid $borderColor;
      align-items: center;

      p {
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 18px;
      }

      .project_view_close {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .projects_item_block {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 20px 20px;
      margin-bottom: 20px;
      overflow-y: auto;

      .no_project_item_block {
        display: flex;
        padding: 10px 0 10px 5px;
        border-bottom: 1px solid $borderColor;

        p {
          margin: 0;
          font-family: 'Montserrat', sans-serif;
          font-weight: 600;
          font-size: 16px;
        }
      }

      .project_item_block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding: 10px 0 10px 5px;
        border-bottom: 1px solid $borderColor;

        p, span {
          flex: 1;
        }

        p {
          margin: 0;
          font-family: 'Montserrat', sans-serif;
          font-weight: 600;
          font-size: 16px;
        }

        span {
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          font-size: 16px;
          margin-left: 10px;
        }

        span {
          text-align: left;
        }

        .hidden {
          visibility: hidden;

          button {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 16px;
            padding: 2px 8px;
            border: 1px solid $borderColor;
            border-radius: 16px;
            visibility: hidden;
          }

          button:first-child {
            margin-right: 10px;
            background: #71c971;
          }

          button:last-child {
            margin-right: 10px;
            background: #ef4e4e;
          }
        }

        .item_action_menu {
          margin-left: 10px;

          button {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 16px;
            padding: 2px 8px;
            border: 1px solid $borderColor;
            border-radius: 16px;
          }

          @media (min-width: 1024px) {
            button {
              visibility: hidden;
            }
          }

          button:first-child {
            margin-right: 10px;
            background: #71c971;
          }

          button:last-child {
            margin-right: 10px;
            background: #ef4e4e;
          }
        }
      }

      .project_item_block:hover {
        background: #d5d5d5;
      }

      .project_item_block:hover .item_action_menu button {
        visibility: visible;
      }
    }
  }
}

@media (max-width: 501px) {
  .project_view_block {

    .project_view_header {

      p {
        font-size: 16px!important;
      }
    }

    .projects_item_block {
      span {
        font-size: 14px!important;
      }

      p {
        font-size: 14px!important;
      }

      .item_action_menu {
        display: flex;
        flex-direction: column;
        justify-content: center;

        button {
          font-size: 14px!important;
          padding: 2px 4px!important;
        }

        button:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
}
