@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Playwrite+HU&display=swap');
@import "../../constants";


header {
    height: 35px;
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid $borderColor;
    background: $corporealBg;

    .header_block {
        height: 35px;
        display: flex;
        align-items: center;
        margin: 0 25px;
        justify-content: space-between;

        .main_link .header_link {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 5px;
            font-family: "Playwrite HU", cursive;
            font-weight: 400;
            color: black;
            font-size: 18px;
        }

        .right_block {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                font-size: 14px;
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
                color: black;
                border: 1px solid $borderColor;
                background: $corporealBg;
                border-radius: 16px;
                padding: 1px 12px;
                transition: all 0.5s ease;
            }
        }
    }
}


